import {lang} from "./config"

const langLib = {
    //unit
    "unit_seconds": ["秒", "s"],
    "unit_minute": ["分", "min"],
    "unit_hour": ["时", "h"],
    //script
    "script_title": ["直播带货脚本", "Live streaming sales script"],
    "script_hint": ["暂无数据", "no data"],
    //live
    "start_live": ["开始直播","Go Live"],
    "start_countdown": ["开播倒计时", "Countdown to launch"],
    "live_back_data1": ["查看", "View"],
    "live_back_data2": ["后台", "backend"],
    "live_back_data3": ["数据", "data"],
    "live_hint": [
        "为了避免直播中的失误，您可以选择先进行直播调试后再开始直播， 或者您也可以选择直接开始。",
        "To avoid errors in the live broadcast, you can choose to debug the live broadcast first before starting the live broadcast, or you can choose to start directly."
    ],
    "turn_sound_hint": ["是否开启声音？", "Whether to turn on sound?"],
    "direct_live_btn": ["直接开播", "Direct broadcast"],
    "dialog_confirm": ["确定", "confirm"],
    "dialog_cancel": ["取消", "cancel"],
    
    "barrage_placeholder": ["说点什么...", "Say something..."],
    "user_like": ["点赞数", "like"],
    "user_fans": ["粉丝数", "fans"],
    "user_home_like": ["本场点赞", "like"],
    "await_live_hint": ["等待主播开始直播", "Wait for the anchor to start broadcasting"],
    "rebroadcast_hint": ["请勿重复直播", "Do not repeat live streaming"],
    "live_NotFoundError": ["没有找到麦克风或摄像头设备", "No microphone or camera device found"],
    "live_NotAllowedError": ["请确保给予麦克风和摄像头设备的权限", "Please ensure permission is granted to microphone and camera devices"],
    "live_common": ["无法获取麦克风和摄像头设备，请尝试刷新网页", "Unable to obtain microphone and camera devices, please try refreshing the webpage"],
    "live_type_err": ["未选择平台直播！", "No platform live!"],
    "live_https_err": ["请使用https协议的网址重新进入页面", "Please use the https address to re-enter the page"],
    "live_script_err": ["本场直播暂无脚本", "There is no script for this live broadcast"],
    "live_user_err": ["获取用户信息异常", "Description Failed to obtain user information"],
    "live_valid_hint": ["网站需要获取您的媒体权限才可正常使用", "Your media rights are required for the website to work properly"],
    "removed_goods_hint": ["请先下架商品", "Please remove the product first"],
    "removed_bag_hint": ["福袋还未结束", "The blessing bag is not over yet"],
    "media_err": ["无法获取媒体设备", "Unable to obtain media device"],
    "room_err": ["请确保已创建直播间，如已创建请重新刷新网页重试", "Please ensure that you have created a broadcast room. If yes, refresh the page and try again"],
    //live_modal
    "modal_title": ["温馨提示", "tips"],
    "modal_pause_content": [
        "暂停直播时，观众将无法看到你的直播画面，单场直播只能暂停3次", 
        "When you pause live stream, the audience will not be able to see your live stream screen, and a single live stream can only be paused 3 times"
    ],
    "modal_pause_cancel": ["我再想想", "I'll think about it again"],
    "modal_pause_confirm": ["暂停直播", "Suspend live streaming"],
    "modal_end_content": ["观众正在赶来的路上，确定结束直播？", "Viewers are on their way. Are you sure we're done?"],
    "modal_end_cancel": ["取消", "cancel"],
    "modal_end_confirm": ["结束直播", "End the broadcast"],
    //live_pause
    "pause_title": ["您已暂停直播，观众无法看到画面", "You have paused the live broadcast and the audience cannot see the picture"],
    "pause_hint": ["后将自动关闭直播", "After that, the live broadcast will be automatically closed"],
    "continue_live": ["继续直播", "Continue live"],
    //live_end
    "end_title": ["直播结束", "End of live broadcast"],
    "view_review": ["查看复盘", "review"],
    "view_other": ["查看他人直播", "View others Live"],
    //live_popup
    "goods_popup_title": ["已选商品", "Selected goods"],
    "goods_coupon_btn": ["发券", "coupons"],
    "goods_current": ["讲解中", "explaining"],
    "goods_removed": ["已讲解", "Explained"],
    "goods_removed": ["已讲解", "Explained"],
    "release": ["发布", "release"],
    "removed": ["下架", "removed"],
    "bag_title": ["福袋", "lucky bag"],
    "bag_scope": ["参与范围", "Scope of participation"],
    "bag_winners": ["可中奖人", "Number of winners"],
    "bag_order": ["参与口令", "Participant password"],
    "bag_countdown": ["倒计时", "countdown"],
    "bag_fans": ["仅限粉丝", "Fans only"],
    "bag_all": ["所有观众", "owner"],
    "bag_hint": ["关注加粉丝团领取超值福袋", "Follow Plus fan group to receive a value bag"],
    "bag_send": ["发送福袋", "Release bag"],
    "bag_sent": ["已发送", "sent"],
    "coupon_title": ["优惠券", "coupon"],
    "coupon_name": ["券", "coupon"],
    "coupon_mark": ["商品券", "merchandise coupon"],
    "coupon_scope": ["仅限指定商品组合使用", "Only for specified product combinations"],
    "coupon_hint": ["每人限领", "Limit per person"],
    "coupon_send1": ["立即", "Instant "],
    "coupon_send2": ["发券", "coupon"],
    "coupon_unit": ["张", "a sheet"],
    "coupon_sent": ["已发放", "sent"],
    "coupon_over": ["满", "over"],
    "coupon_use": ["元可用", "available"],
    "coupon_currency": ["￥", "$"],
    "coupon_discount": ["折", "% Off"],
    "coupon_pad": ["", "0"],
    "coupon_minus": ["减", "minus"],
    "coupon_hit": ["打", "is"],
    "coupon_scramble": ["抢", "scramble"],
    //live data
    "distance_train_end": ["距离实训结束","Distance training is over"],
    "distance_train_start": ["距离实训开始","Distance from the training begins"],
    "distance_live_end": ["距离直播结束","Distance broadcast end"],
    "live_time": ["直播时长", "Live broadcast duration"],
    "live_data": ["人气数据", "Popularity data"],
    "online_num": ["实时在线人数", "online number"],
    "sale_num": ["成交件数", "sales volume"],
    "enter_num": ["进入直播间人数", "Number of entrants"],
    "like_num": ["点赞数", "Like count"],
    "leave_num": ["离开直播间人数", "Number of departures"],
    //bullet chat
    "history_barrage": ["历史弹幕", "Historical barrage"],
}

export default lang(langLib)
