import { lang } from "./config";
const othersLive = {
  return: ["返回", "Return"],
  no_live: ["暂无直播", "No Live"],
  no_anchor: ["暂无主播", "No Anchor"],
  no_on_air: ["未开播", "Not On Air"],
  living: ["直播中", "Living Broadcast"],
  lived: ["直播结束", "Live Finish"],
  living_suspend: ["直播暂停", "Live Suspension"],
  connecting: ["连接中", "Connecting"],
  fans: ["粉丝", "Fans"],
  focus: ["关注", "Focus"],
  like_num: ["点赞数", "Like Number"],
  lucky_bag: ["福袋", "Lucky Bag"],
  discount_coupon: ["优惠券", "Discount Coupon"],
  say_something: ["说点什么...", "Say Something..."],
  explaining: ["讲解中", "Explain"],
  full: ["满", "Full"],
  minus: ["减", "Minus"],
  hit: ["打", "Hit"],
  discount: ["折", "Discount"],
  get_bag_msg: ["关注我才可以领福袋哦", "Follow me to get the bag"],
  think_again: ["我再想想", "I'll think about it again"],
  attention: ["立即关注", "Immediate Attention"],
  count_down: ["倒计时", "Count Down"],
  winners_num: ["可中奖数", "Number of possible winners"],
  person: ["人", "Person"],
  participation: ["已参与", "Participation"],
  participation_rule: ["参与条件", "Participation Rule"],
  participant_password: ["参与口令", "Participant Password"],
  password: ["去发送口令", "Go send password"],
  open_sound: ["是否开启声音？", "Do you want to turn on sound?"],
  unit: ["元", "Unit"],
  usable: ["可用", "Usable"],
  goods_coupon: ["商品券", "Goods Coupon"],
  use_rule: ["仅限指定商品组合使用", "Use only for selected product combinations"],
  limit_person: ["每人限领", "Collar limit per person"],
  sheet: ["张", "Sheet"],
  claimed: ["已领完", "Claimed"],
  immediate: ["立即", "Immediate"],
  coupon: ["领券", "Coupon"],
  congratulations: ["恭喜你，领券成功", "Congratulations, you got the ticket"],
  online: ["在线人数TOP10", "TOP10 online users"],
  history_barrage: ["历史弹幕", "History Barrage"],
  open_barrage: ["请开启弹幕功能！", "Please turn on the barrage function!"],
  cancel_focus_msg: ["确定不再关注此人？", "Are you sure you don't want to focus on this person?"],
  confirm: ["确定", "Confirm"],
  cancel: ["取消", "Cancel"],
  no_coupons: ["优惠券已领完！", "The coupons have been claimed"],
};
export default lang(othersLive);
